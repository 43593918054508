<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.625 12.125L20.8664 22.7493C20.8349 23.1908 20.6374 23.604 20.3135 23.9056C19.9896 24.2073 19.5634 24.375 19.1208 24.375H11.8792C11.4366 24.375 11.0104 24.2073 10.6865 23.9056C10.3626 23.604 10.1651 23.1908 10.1336 22.7493L9.375 12.125M13.75 15.625V20.875M17.25 15.625V20.875M18.125 12.125V9.5C18.125 9.26794 18.0328 9.04538 17.8687 8.88128C17.7046 8.71719 17.4821 8.625 17.25 8.625H13.75C13.5179 8.625 13.2954 8.71719 13.1313 8.88128C12.9672 9.04538 12.875 9.26794 12.875 9.5V12.125M8.5 12.125H22.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="16" cy="16" r="15" stroke="currentColor" stroke-width="2"/>
    </svg>
</template>

<script>
export default {
    name: "TrashOutline"
}
</script>